// extracted by mini-css-extract-plugin
export const postPage = "project-module--postPage--3W_3u";
export const postHeader = "project-module--postHeader--z8J-M";
export const postIcon = "project-module--postIcon--3EJ94";
export const postContent = "project-module--postContent--3aIo0";
export const postField = "project-module--postField--zzxPi";
export const postKeyword = "project-module--postKeyword--3bdqD";
export const postDesc = "project-module--postDesc--2ZnQd";
export const duration = "project-module--duration--2nf8n";
export const postMeta = "project-module--postMeta--JLxz9";
export const postMetaFields = "project-module--postMetaFields--3zI6S";
export const postIllustration = "project-module--postIllustration--2KP1L";
export const postIllustrationFrame = "project-module--postIllustrationFrame--1Dx3H";
export const tags = "project-module--tags--CRW1K";