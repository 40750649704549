import React from 'react';
import { Link } from 'gatsby';
import replaceSlashes from '../../utils/replaceSlashes';
import useSiteMetadata from '../../utils/useSiteMetadata';
import * as styles from './itemTags.module.css';

type TagProp = {
  className: any;
  tag: {
    name: string;
    slug: string;
  };
};

const ItemTag = ({ tag, className }: TagProps) => {
  const { tagsPath, basePath } = useSiteMetadata();

  return (
    <React.Fragment key={tag.slug}>
      <Link
        className={`${styles.tag} ${className || ''}`}
        to={replaceSlashes(`/${basePath}/${tagsPath}/${tag.slug}`)}
      >
        {tag.name}
      </Link>
    </React.Fragment>
  );
};

export default ItemTag;
