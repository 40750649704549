import React from 'react';
import { Link } from 'gatsby';
import replaceSlashes from '../../../utils/replaceSlashes';
import useSiteMetadata from '../../utils/useSiteMetadata';
import * as styles from './itemTags.module.css';
import ItemTag from './ItemTag';

type TagsProps = {
  tags: {
    name: string;
    slug: string;
  }[];
};

const ItemTags = ({ tags }: TagsProps) => {
  const { tagsPath, basePath } = useSiteMetadata();

  return (
    <>
      {tags.map((tag, i) => (
        <React.Fragment key={tag.slug}>
          {!!i && <span className={styles.tagsDivider}>/</span>}
          <ItemTag tag={tag} />
        </React.Fragment>
      ))}
    </>
  );
};

export default ItemTags;
