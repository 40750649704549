import React from 'react';
import { graphql } from 'gatsby';
import Project from '../components/Views/Project/Project';

type Props = {
  data: {
    post: any;
    [key: string]: any;
  };
};

export const query = graphql`
  query($slug: String!) {
    post(slug: { eq: $slug }) {
      slug
      title
      tagLine
      releasedProject
      date(formatString: "MMMM D, YYYY")
      startDate
      endDate
      tags {
        name
        slug
      }
      description
      body
      excerpt
      timeToRead
      pic {
        childImageSharp {
          resize(width: 348, height: 208, quality: 90) {
            src
          }
        }
        extension
        publicURL
      }
      skills
      url
      pageViews
      fieldNotes {
        field
        note
      }
    }
  }
`;

export default ({ data }: Props) => {
  const { post } = data;

  return <Project data={{ ...data, post }} />;
};
