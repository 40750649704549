import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import moment from 'moment';
import Layout from '../../Layout/Layout';
import ItemTags from '../../ItemTags/ItemTags';
import * as listStyles from '../../ItemList/itemList.module.css';
import * as styles from './project.module.css';
import * as pageStyles from '../Page/page.module.css';
import TreeIcon from '../../../assets/icons/tree.inline.svg';

type PostProps = {
  data: {
    post: {
      slug: string;
      title: string;
      date: string;
      startDate: string;
      endDate: string;
      tags?: {
        name: string;
        slug: string;
      }[];
      description?: string;
      body: string;
      excerpt: string;
      timeToRead: number;
      skills: string;
      banner?: {
        childImageSharp: {
          resize: {
            src: string;
          };
        };
      };
      pic?: {
        childImageSharp: {
          resize: {
            src: string;
          };
        };
        extension;
        publicURL;
      };
      url: string;
      pageViews: string;
      fieldNotes?: {
        [key: string]: string;
      }[];
    };
  };
};

const Post = ({ data: { post } }: PostProps) => {
  let months;
  const fieldNotes = {};

  let { endDate } = post;
  let { title } = post;

  const titleParts = title.split('\n');

  if (titleParts.length > 1) {
    title = titleParts.map((part) => (
      <>
        {part} <br />
      </>
    ));
  }

  if (!post.endDate) {
    endDate = moment();
  }

  if (post.startDate) {
    const period = moment.duration(
      moment(endDate).diff(moment(post.startDate))
    );
    months = moment
      .duration(Math.floor(period.asMonths()), 'months')
      .humanize();
  }

  if (post.fieldNotes) {
    post.fieldNotes.forEach((fieldNote) => {
      fieldNotes[fieldNote.field] = fieldNote.note;
    });
  }

  const startDateDisplay = moment(post.startDate).format('MMMM D, YYYY');
  const endDateDisplay = post.endDate
    ? moment(post.endDate).format('MMMM D, YYYY')
    : 'now';

  return (
    <Layout
      currentPage={{
        slug: post.slug,
        title: post.title,
        name: 'project',
        footer: 'WebView HTML',
      }}
    >
      <div className={styles.postPage}>
        <div className={`${styles.postHeader}`}>
          <div className={listStyles.listTitle}>
            <h1 className={pageStyles.pageTitle}>
              {title} {post.tagLine ? `- ${post.tagLine}` : ''}
            </h1>
            {post.tags && (
              <div className={styles.tags}>
                <ItemTags tags={post.tags} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.postContent}>
          <div className={styles.postMeta}>
            <div className={styles.postMetaFields}>
              {!post.releasedProject && (
                <div>
                  <time>
                    <span className={styles.postKeyword}>Release date</span>:{' '}
                    {post.date}
                  </time>
                </div>
              )}
              {post.startDate && (
                <div className={styles.postField}>
                  <span className={styles.postKeyword}>From</span>{' '}
                  {startDateDisplay}{' '}
                  <span className={styles.postKeyword}>to</span>{' '}
                  {endDateDisplay} {fieldNotes.endDate}
                  {months && (
                    <span className={styles.duration}> ({months})</span>
                  )}
                </div>
              )}

              {post.skills && (
                <div className={styles.postField}>
                  <span className={styles.postKeyword}>Skills:</span>{' '}
                  {post.skills}
                </div>
              )}
              {post.description && (
                <div className={styles.postField}>
                  <span className={styles.postKeyword}>Description:</span>{' '}
                  <span className={`${styles.postDesc} ${styles.postField}`}>
                    {post.description}
                  </span>
                </div>
              )}
              {post.pageViews && (
                <div className={styles.postField}>
                  <span className={styles.postKeyword}>Page views:</span>{' '}
                  {post.pageViews}
                </div>
              )}
              {(post.url || fieldNotes.url) && (
                <div className={styles.postField}>
                  <span className={styles.postKeyword}>URL:</span>{' '}
                  {post.url && (
                    <a
                      target='_blank'
                      rel='noreferrer'
                      rel='noopener'
                      href={
                        post.url.indexOf('http') > -1
                          ? post.url
                          : post.slug + '/' + post.url
                      }
                      title={post.title}
                    >
                      {fieldNotes.urlTitle ? fieldNotes.urlTitle : post.url}
                    </a>
                  )}{' '}
                  {fieldNotes.url}
                </div>
              )}
            </div>
            <div className={styles.postIllustrationFrame}>
              {post.pic &&
              !post.pic.childImageSharp &&
              post.pic.extension === 'svg' ? (
                <img
                  className={styles.postIllustration}
                  src={post.pic.publicURL}
                  alt='pic'
                />
              ) : (
                post.pic &&
                post.pic.childImageSharp && (
                  <img
                    className={styles.postIllustration}
                    src={post.pic.childImageSharp.resize.src}
                    alt='pic'
                  />
                )
              )}
            </div>
          </div>
          <div>
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Post;
